<template>
    <BT-Blade-Items
        addBladeName="journey-template"
        bladeName="journey-templates"
        :bladesData="bladesData"
        canAdd
        canDelete
        canSearchLocal
        clearBlades
        :headers="[
            { text: 'Name', value: 'journeyName', title: 1, searchable: true },
            { text: 'Start Location', value: 'startLocation.locationName', searchable: true },
            { text: 'End Location', value: 'endLocation.locationName', searchable: true },
            { text: 'Max Destinations', value: 'maxDestinations', textFilter: 'toDisplayNumber' }]"
        navigation="journey-templates"
        title="Journey Templates"
        useServerPagination
        :defaultBladeWidth="350">
        <template v-slot:settings>
            <v-list-item dense :to="{ name: 'template-optimization-hub' }">
                <v-list-item-icon>
                    <v-icon small>mdi-image-area</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>Coverage Board</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Journey-Templates-Blade',
    props: {
        bladesData: null
    },
}
</script>